<template>
  <form-container class="form-container"
    title="Realizar solicitud de recogida"
    description="Índica las órdenes para las cuales deseas solicitar la recogida. Si eliges órdenes con diferentes transportistas, se generarán solicitudes de recogida individuales para cada uno de ellos"
    buttonText="Crear"
    @submit="save"
    :useLoading="useLoading">
    <template v-slot:content class="form-content">
      <div class="orders-container" v-if="showOrders">
        <v-list two-line>
          <v-list-item-group
            v-model="collection.orders"
            active-class="group-active"
            multiple>
            <div v-for="(order, index) in orders.orders" :key="order.order_id">
              <template>
                <v-list-item>
                  <template v-slot:default>
                    <v-list-item-content>
                      <v-list-item-title class="orders-title">
                        <b>Orden:</b>&nbsp;{{ order.order_number }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="orders-guide">
                        <span v-if="order.guide">
                          <b>Guía:</b>&nbsp;{{ order.guide.guide_number }}
                        </span>
                        <span v-else>
                          <a class="generate-guide-link" @click.stop="generateOrderGuide(order.order_id, index)">Generar guía</a>
                        </span>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                      {{ order.user.first_name }}&nbsp;{{ order.user.last_name }}
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action class="carrier-container">
                      <v-list-item-action-text>
                        {{ dateFormat(order.creation_date, 'D MMM') }}
                      </v-list-item-action-text>
                      <div v-if="!!getCarrierInfo(order.delivery.carrier_id)">
                        <v-avatar size="28">
                          <img
                            :src="getCarrierInfo(order.delivery.carrier_id).logo_mini"
                            :alt="getCarrierInfo(order.delivery.carrier_id).name"
                          >
                        </v-avatar>
                      </div>
                    </v-list-item-action>
                  </template>
                </v-list-item>
                <v-divider v-if="index < orders.orders.length - 1" :key="index" />
              </template>
            </div>
          </v-list-item-group>
        </v-list>
      </div>
      <div v-else>
        <v-skeleton-loader v-for="i in 3" type="list-item-three-line" :key="i + '_item'"></v-skeleton-loader>
      </div>
      <v-row no-gutters class="mt-6">
        <v-col cols="12">
          <v-textarea
            v-model="collection.comment"
            label="Comentarios"
            outlined
            dense
            rows="3"
          />
        </v-col>
      </v-row>
    </template>
  </form-container>
</template>
<script>
import { isRequired } from '@/shared/validations'
import { mapState, mapActions } from 'vuex'
import FormContainer from '@/components/Form/FormContainer'
import { DELIVERY_PROVIDER_AVEONLINE, getCarrierInfo } from '@/shared/delivery'
import { QUERY_TYPE_ORDERS_WITHOUT_COLLECTION } from '@/shared/queryTypes'
import { dateFormat } from '@/shared/format'

export default {
  components: {
    FormContainer
  },
  props: {
    collection: {
      type: Object,
      default: function () {
        return {
          provider: DELIVERY_PROVIDER_AVEONLINE,
          orders: [],
          order_ids: [],
          comment: null
        }
      }
    }
  },
  computed: {
    ...mapState('shared', ['error']),
    ...mapState('order', ['orders']),
    showOrders () {
      return this.orders && this.orders.orders
    }
  },
  data () {
    return {
      useLoading: true
    }
  },
  methods: {
    isRequired,
    dateFormat,
    getCarrierInfo,
    ...mapActions('notification', ['showNotification']),
    ...mapActions('order', ['queryOrders', 'generateGuide', 'updateOrderInList']),
    async generateOrderGuide (orderID, index) {
      const order = await this.generateGuide(orderID)
      if (this.error) {
        this.showNotification({ message: this.error.message, color: 'var(--app-popup-bg-color-danger)', y: 'top', x: 'right', buttonColor: '#FFFFFF' })
        return
      }

      this.updateOrderInList({ order, index })

      this.showNotification({ message: 'Guía generada exitosamente.', color: 'var(--app-popup-bg-color-success)', y: 'top', x: 'right', buttonColor: '#FFFFFF' })
    },
    save () {
      if (this.collection.orders.length === 0) {
        this.showNotification({ message: 'Por favor, seleccione al menos una orden', color: 'var(--app-popup-bg-color-danger)', y: 'top', x: 'right', buttonColor: '#FFFFFF' })
        return
      }

      for (const index of this.collection.orders) {
        const order = this.orders.orders[index]
        if (!order) {
          continue
        }

        if (!order.guide) {
          this.showNotification({ message: 'Algunas órdenes seleccionadas no tienen guía, por favor genérelas.', color: 'var(--app-popup-bg-color-danger)', y: 'top', x: 'right', buttonColor: '#FFFFFF' })
          return
        }

        this.collection.order_ids.push(order.order_id)
      }

      this.$emit('submit', this.collection)
    }
  },
  async mounted () {
    await this.queryOrders(QUERY_TYPE_ORDERS_WITHOUT_COLLECTION)
    this.useLoading = false
  }
}
</script>
<style scoped>
.form-container {
  height: 100%;
}
.form-content {
  height: 100%;
}
.orders-container {
  max-height: 20.4rem;
  overflow-y: scroll;
  border: 0.063rem solid rgb(180, 180, 180);
}
.orders-container::-webkit-scrollbar-track {
  background: #F1F1F1;
}

.orders-container::-webkit-scrollbar-thumb {
  background: #888888;
  border-radius: 4px;
}
.orders-container::-webkit-scrollbar-thumb:hover {
  background: #555555;
}
.orders-container::-webkit-scrollbar {
  width: 10px;
}
.group-active {
  color: #009688;
}
.orders-title {
  font-size: 14px;
}
.orders-guide {
  color: #000000DE !important;
}
.generate-guide-link {
  text-decoration: underline;
  cursor: pointer;
  font-size: 0.75rem;
  font-weight: 600;
  margin-top: 0.5rem;
}
.carrier-container {
  display: flex;
  align-items: center;
}
</style>

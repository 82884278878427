<template>
  <collection-form @submit="create" />
</template>
<script>
import { mapState, mapActions } from 'vuex'
import navigationMixin from '@/mixins/navigation'
import CollectionForm from '@/components/Collection/CollectionForm'

export default {
  mixins: [navigationMixin],
  components: {
    CollectionForm
  },
  computed: {
    ...mapState('shared', ['error'])
  },
  methods: {
    ...mapActions('collection', ['createCollection']),
    ...mapActions('notification', ['showNotification']),
    async create (collection) {
      await this.createCollection(collection)
      if (this.error) {
        this.showNotification({ message: this.error.message, color: 'var(--app-popup-bg-color-danger)', y: 'top', x: 'right', buttonColor: '#FFFFFF' })
        return
      }

      this.showNotification({ message: 'Solicitud de recogida creada exitosamente.', color: 'var(--app-popup-bg-color-success)', y: 'top', x: 'right', buttonColor: '#FFFFFF' })
      this.goToRouteByName('collections')
    }
  }
}
</script>
<style scoped>
</style>
